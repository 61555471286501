<template>
  <sdPageHeader title="Products"> </sdPageHeader>
  <Main>
    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Product filter">
          <a-form :model="productForm" layout="vertical">
            <a-row :gutter="25">
              <a-col :span="6">
                <a-form-item label="HS code" name="hsCodeId">
                  <a-select
                    :size="'small'"
                    v-model:value="productForm.hsCodeId"
                    placeholder="HS Code"
                    show-search
                    style="width: 100%"
                    :options="hsCodeData"
                    :not-found-content="hsCodeFetching ? undefined : null"
                    :filter-option="false"
                    @search="searchHSCode"
                  >
                    <template v-if="hsCodeFetching" #notFoundContent>
                      <a-spin size="small" />
                    </template>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Category" name="categoryId">
                  <a-select
                    size="small"
                    v-model:value="productForm.categoryId"
                    placeholder="category"
                  >
                    <a-select-option
                      v-for="(category, index) in CategoryApi"
                      :key="index"
                      :value="category.id"
                    >
                      {{ category.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Size" name="sizeId">
                  <a-select
                    v-model:value="productForm.sizeId"
                    placeholder="Size"
                    @change="SelectWeights"
                  >
                    <a-select-option
                      v-for="(size, index) in SizeApi"
                      :key="index"
                      :value="size.id"
                    >
                      {{ size.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Weight" name="weightId">
                  <a-select
                    :disabled="!productForm.sizeId"
                    v-model:value="productForm.weightId"
                    placeholder="Weight"
                  >
                    <a-select-option
                      v-for="(weight, index) in SizeWeight"
                      :key="index"
                      :value="weight.id"
                    >
                      {{ weight.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Grade" name="gradeId">
                  <a-select
                    v-model:value="productForm.gradeId"
                    placeholder="Grade"
                  >
                    <a-select-option
                      v-for="(grade, index) in GradeApi"
                      :key="index"
                      :value="grade.id"
                    >
                      {{ grade.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Range" name="rangeId">
                  <a-select
                    v-model:value="productForm.rangeId"
                    placeholder="Range"
                  >
                    <a-select-option
                      v-for="(range, index) in RangeApi"
                      :key="index"
                      :value="range.id"
                    >
                      {{ range.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Thread" name="threadId">
                  <a-select
                    v-model:value="productForm.threadId"
                    placeholder="Thread"
                  >
                    <a-select-option
                      v-for="(thread, index) in ThreadApi"
                      :key="index"
                      :value="thread.id"
                    >
                      {{ thread.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item
                  label="Possibility of partial sale"
                  name="isPartialSale"
                >
                  <a-select
                    v-model:value="productForm.isPartialSaleInt"
                    placeholder="is partial sale ?"
                  >
                    <a-select-option :value="1">Yes</a-select-option>
                    <a-select-option :value="0">No</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item
                  label="Units of Measurement"
                  name="unitOfMeasureId"
                >
                  <a-select
                    v-model:value="productForm.unitOfMeasureId"
                    placeholder="Please choose Owner"
                  >
                    <a-select-option
                      v-for="(unit, index) in UnitApi"
                      :key="index"
                      :value="unit.id"
                    >
                      {{ unit.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6">
                <a-form-item label="Curency" name="currencyId">
                  <a-select
                    v-model:value="productForm.currencyId"
                    placeholder="currency"
                  >
                    <a-select-option
                      v-for="(currency, index) in CurrencyApi"
                      :key="index"
                      :value="currency.id"
                    >
                      {{ currency.name }}</a-select-option
                    >
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button
                  class="btn ant-btn-lg client-form-submit"
                  outlined
                  type="primary"
                  @click="onSubmit"
                >
                  <span>Search</span>
                </a-button>
              </a-col>
              <a-col :span="6" class="button-container">
                <a-button
                  @click="clearFilters"
                  class="btn-outlined ant-btn-lg client-form-submit"
                  outlined
                >
                  <span>Clear Filters</span>
                </a-button>
              </a-col>
            </a-row>
          </a-form>
        </sdCards>
      </a-col></a-row
    >

    <a-row :gutter="15">
      <a-col class="w-100" :md="24">
        <sdCards title="Products">
          <div>
            <a-table
              :columns="columns"
              :dataSource="productsData"
              :pagination="pagination"
              @change="onHandleTableChange"
              class="components-table-demo-nested"
              :loading="isLoading"
            >
              <template #status="{ record }">
                <span v-if="record.product.isPartialSale"> Yes </span>
                <span v-else> No </span>
              </template>
              <template #expandedRowRender="{ record }">
                <a-table
                  class="inner-table"
                  v-if="record.product.productType == 1"
                  :columns="innerOCTGColumns"
                  :data-source="[{ ...record.product, key: record.product.id }]"
                  :pagination="false"
                >
                </a-table>
                <a-table
                  class="inner-table"
                  v-else
                  :columns="innerOtherColumns"
                  :data-source="[{ ...record.product, key: record.product.id }]"
                  :pagination="false"
                >
                </a-table>
              </template>
            </a-table>
          </div>
        </sdCards>
      </a-col>
    </a-row>
  </Main>
</template>

<script>
import { defineComponent, ref, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { Main } from "../styled";
import useHsCodeSearch from "../../composable/useHsCodeSearch";

const columns = [
  {
    title: "Product Type",
    dataIndex: "type",
    key: "type",
  },
  {
    title: "Quantity",
    dataIndex: "product.quantityInitial",
    key: "quantityInitial",
  },
  {
    title: "Virtual Quantity",
    dataIndex: "product.quantityVirtuelle",
    key: "quantityVirtuelle",
  },
  {
    title: "Quantity in Stock",
    dataIndex: "product.quantityInStock",
    key: "quantityInStock",
  },
  {
    title: "Category",
    dataIndex: "product.categoryName",
    key: "categoryName",
  },
  {
    title: "Partial Sale",
    key: "isPartialSale",
    slots: {
      customRender: "status",
    },
  },
  // {
  //   title: "Status",
  //   key: "state",
  //   slots: {
  //     customRender: "status",
  //   },
  // },
  {
    title: "Quotation",
    dataIndex: "deal",
    key: "deal",
  },
];

const innerOCTGColumns = [
  {
    title: "Unit of Measurement",
    dataIndex: "unitOfMeasureName",
    key: "unitOfMeasureName",
  },
  {
    title: "Size",
    dataIndex: "sizeName",
    key: "sizeName",
  },
  {
    title: "Weight",
    dataIndex: "weightName",
    key: "weightName",
  },
  {
    title: "Grade",
    dataIndex: "gradeName",
    key: "gradeName",
  },
  {
    title: "Thread",
    dataIndex: "threadName",
    key: "threadName",
  },
  {
    title: "Range",
    dataIndex: "rangeName",
    key: "rangeName",
  },
];
const innerOtherColumns = [
  {
    title: "Unit of Measurement",
    dataIndex: "unitOfMeasureName",
    key: "unitOfMeasureName",
  },
  {
    title: "HS code",
    dataIndex: "hsCodeName",
    key: "sizeName",
  },
];

const Products = defineComponent({
  name: "Products",
  components: {
    Main,
  },

  setup(props, { emit }) {
    const { state, dispatch } = useStore();
    const ProductApi = computed(() => state.ProductApi.data.data);
    const ProductPagination = computed(() => state.ProductApi.data);
    const SizeApi = computed(() => state.SizeApi.data);
    const CategoryApi = computed(() => state.CategoryApi.data);
    const GradeApi = computed(() => state.GradeApi.data);
    const UnitApi = computed(() => state.UnitApi.data);
    const ThreadApi = computed(() => state.ThreadApi.data);
    const RangeApi = computed(() => state.RangeApi.data);
    const WeightApi = computed(() => state.WeightApi.data);
    const CurrencyApi = computed(() => state.CurrencyApi.data);
    const isLoading = computed(() => state.ProductApi.loading);

    var SizeWeight = ref([]);
    pagination = {
      current: 1,
      pageSize: 10,
    };
    onMounted(() => {
      productForm.isPartialSale = !!productForm.isPartialSaleInt;
      pagination = {
        productForm,
        current: 1,
        pageSize: 10,
      };

      dispatch("allProductsGetData", {
        pagination,
      });
      dispatch("sizeInfo");
      dispatch("categoryInfo");
      dispatch("gradeInfo");
      dispatch("unitInfo");
      dispatch("threadInfo");
      dispatch("rangeInfo");
      dispatch("weightInfo");
      dispatch("currencyInfo");
    });

    const SelectWeights = (size) => {
      SizeWeight.value = WeightApi.value.filter(
        (wieght) => wieght.sizeId === size
      );
    };

    const clearFilters = () => {
      productForm.name = "";
      productForm.isPartialSaleInt = 1;
      productForm.categoryId = "";
      productForm.sizeId = "";
      productForm.hsCodeId = "";
      productForm.gradeId = "";
      productForm.weightId = "";
      productForm.rangeId = "";
      productForm.threadId = "";
      productForm.unitOfMeasureId = "";
      productForm.currencyId = "";
      productForm.currencyId = "";

      productForm.isPartialSale = !!productForm.isPartialSaleInt;
      pagination = {
        productForm,
        current: 1,
        pageSize: 10,
      };
      dispatch("allProductsGetData", {
        pagination,
      });
    };
    const OCTG = 1;

    const productsData = computed(() => {
      if (ProductApi.value && ProductApi.value.length) {
        return ProductApi.value.map((product) => {
          return {
            key: product.id,
            type: product.productType == OCTG ? "OCTG" : "Other",
            product,
            deal: (
              <div class="table-actions">
                <router-link
                  class="edit"
                  to={"/purchase/edit-deal/" + product.dealId}
                >
                  <sdFeatherIcons type="edit" size={14} />
                </router-link>
              </div>
            ),
          };
        });
      } else {
        return [];
      }
    });

    var pagination = computed(() =>
      ProductPagination.value && ProductPagination.value.succeeded == true
        ? {
            current: ProductPagination.value.pageNumber,
            total:
              ProductPagination.value.recordsFiltered ??
              ProductPagination.value.recordsTotal,
            pageSize: ProductPagination.value.pageSize,
          }
        : {
            current: 1,
            pageSize: 10,
          }
    );
    // #region modal function
    const handleOk = () => {
      emit("hideProducts", false);
      pagination = {
        current: 1,
        pageSize: 10000,
      };
    };
    const handleCancel = () => {
      emit("hideProducts", false);
    };
    const SelectedProduct = (product) => {
      let productToAdd = {
        productId: product.id,
        saleId: props.saleId,
        unitPrice: product.priceForSales,
        quantity: 0,
      };
      dispatch("productOrderSubmitData", { productToAdd });
      emit("SelectedProduct", productToAdd);
    };
    // #endregion

    // #region filters
    var productForm = reactive({
      layout: "vertical",
      name: "",
      isPartialSaleInt: 1,
      categoryId: "",
      sizeId: "",
      gradeId: "",
      weightId: "",
      hsCodeId: "",
      rangeId: "",
      threadId: "",
      unitOfMeasureId: "",
      currencyId: "",
      countryId: "",
    });
    const productFormRef = ref([]);

    const onHandleTableChange = (paginationTable) => {
      pagination = {
        ...pagination,
        current: paginationTable.current,
      };
      dispatch("allProductsGetData", { pagination });
    };

    const onSubmit = () => {
      productForm.isPartialSale = !!productForm.isPartialSaleInt;
      pagination = {
        productForm,
        current: 1,
        pageSize: 10,
      };

      dispatch("allProductsGetData", {
        pagination,
      });
    };
    // #end region
    const { searchHSCode, hsCodeData } = useHsCodeSearch(productForm.hsCodeId);

    return {
      searchHSCode,
      hsCodeData,
      innerOCTGColumns,
      innerOtherColumns,
      pagination,
      ProductApi,
      columns,
      clearFilters,
      handleOk,
      handleCancel,
      SelectedProduct,
      productForm,
      productFormRef,
      SizeApi,
      CategoryApi,
      GradeApi,
      UnitApi,
      ThreadApi,
      RangeApi,
      WeightApi,
      CurrencyApi,
      SelectWeights,
      SizeWeight,
      onSubmit,
      onHandleTableChange,
      productsData,
      isLoading,
    };
  },
});
export default Products;
</script>
<style lang="scss" scoped>
.custom,
.ant-calendar-picker-input.ant-input {
  height: 38px;
}
.button-container {
  align-self: center !important;
  .ant-btn {
    height: 38px;
    width: 100%;
  }
}
</style>
